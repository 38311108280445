<template>
  <div class="">
    <div class="d-flex justify-content-start">
      <input
        type="search"
        class="form-control col-3"
        v-model="searchValue"
        placeholder="Search program name"
      />
      <button
        disabled
        href="#"
        class="btn btn-primary ml-3"
        @click.prevent="showAddProgramModal"
      >
        ADD PROGRAM
      </button>
    </div>
    <div class="mt-3">
      <table
        id="hei-programs-table"
        class="table table-hover table-bordered table-sm"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>PROG. ID</th>
            <th>DISC. CODE</th>
            <th width="30%">PROGRAM NAME</th>
            <th>MAJOR</th>
            <th>PERMIT NO</th>
            <th>DATE ISSUED</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-if="!filteredHeiPrograms.length && !tableLoader"
          >
            <td colspan="8">
              No results found
            </td>
          </tr>
          <tr class="text-center" v-if="tableLoader">
            <td colspan="8">
              <div class="spinner-border spinner-border-sm mr-1"></div>
              Loading
            </td>
          </tr>
          <tr
            v-for="(hei_program, index) in filteredHeiPrograms"
            :key="hei_program.id"
            @click.stop="showEditProgramModal(hei_program)"
          >
            <td>{{ index + 1 }}</td>
            <th scope="row">{{ hei_program.program_id }}</th>
            <td>{{ hei_program.discipline_code }}</td>
            <td>
              {{ hei_program.program_name }}
              <span
                v-if="hei_program.former_name"
                class="small form-text text-muted mt-0"
                >Formerly: {{ hei_program.former_name }}</span
              >
            </td>
            <td>{{ hei_program.major }}</td>
            <td>
              <button
                v-if="hei_program.permit_no_file_path"
                @click.stop="openUrl(hei_program.permit_no_file_path)"
                class="btn btn-link px-0 py-0"
              >
                <i class="bi bi-file-pdf"></i> {{ hei_program.permit_no }}
              </button>
              <span v-else>{{ hei_program.permit_no }}</span>
            </td>

            <td>
              <span v-show="hei_program.date_issued">{{
                hei_program.date_issued | standardDate
              }}</span>
            </td>
            <td>
              <span
                class="badge badge-pill"
                :class="
                  hei_program.status == 'Active'
                    ? 'badge-success'
                    : 'badge-danger'
                "
                >{{ hei_program.status }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!----------- START - ADD PROGRAM MODAL -------------->

    <div class="modal" id="add-program-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <span v-if="editMode">Edit Program</span
              ><span v-else>Add Program</span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="editMode ? editProgram() : addProgram()">
            <div class="modal-body">
              <div class="form-group">
                <label for=""
                  ><span class="text-danger">*</span> Select program</label
                >
                <select
                  v-show="!editMode"
                  v-model="form.program_id"
                  name=""
                  id=""
                  class="form-control"
                  required
                >
                  <option value="" selected hidden disabled>Choose</option>
                  <option
                    v-for="program in programs"
                    :key="program.id"
                    :value="program.id"
                  >
                    {{ program.program_name }}
                  </option>
                </select>

                <input
                  v-show="editMode"
                  type="text"
                  class="form-control"
                  v-model="form.program_name"
                  disabled
                />
              </div>

              <div class="form-row">
                <div class="col form-group">
                  <label for=""
                    ><span class="text-danger">*</span> Permit Number</label
                  >
                  <input
                    v-model="form.permit_no"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                <div class="col form-group">
                  <label for=""
                    ><span class="text-danger">*</span> Date issued</label
                  >
                  <input
                    v-model="form.date_issued"
                    type="date"
                    class="form-control"
                    required
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col form-group">
                  <label for="">Accreditation Level</label>
                  <select
                    v-model="form.accreditation_level"
                    name=""
                    id=""
                    class="custom-select"
                  >
                    <option value="" selected hidden disabled
                      >Select level</option
                    >
                    <option value="">Level I</option>
                    <option value="">Level II</option>
                  </select>
                </div>

                <div class="col form-group">
                  <label for="">Validity</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="form.validity"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="">Program Head</label>
                <input
                  v-model="form.program_chair"
                  type="text"
                  class="form-control"
                  placeholder="Enter complete name"
                />
              </div>

              <div class="form-group">
                <label for=""><span class="text-danger">*</span> Status</label>
                <select
                  v-model="form.status"
                  name=""
                  id=""
                  class="custom-select"
                  required
                >
                  <option value="Active">
                    Active
                  </option>
                  <option value="Inactive">
                    Inactive
                  </option>
                  o
                  <option value="Closed">Closed</option>
                  <option value="Change of Nomenclature"
                    >Change of Nomenclature</option
                  >
                  <option value="Gradual Phase Out">
                    Gradual Phase Out
                  </option>
                  <option value="Phased out">
                    Phased out
                  </option>
                  <option value="Deferred">Deferred</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                CLOSE
              </button>
              <button
                id="add-program-btn"
                v-if="!editMode"
                type="submit"
                class="btn btn-primary"
              >
                INSERT
              </button>
              <button
                id="edit-program-btn"
                v-else
                type="submit"
                class="btn btn-primary"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!----------- END - ADD PROGRAM MODAL ---------------->
  </div>
</template>

<script>
export default {
  name: 'HeiPrograms',
  props: ['hei_data'],
  components: {},

  data() {
    return {
      hei_programs: '',
      programs: [],
      editMode: false,
      form: new Form({
        program_id: '',
        hei_program_id: '',
        hei_code: this.hei_data.code,
        program_id: '',
        program_name: '',
        status: '',
        permit_no: '',
        date_issued: '',
        accreditation_level: '',
        validity: '',
        program_chair: '',
      }),
      prefix: 'https://drive.google.com/file/d/',
      searchValue: '',
      tableLoader: false,
    };
  },
  methods: {
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    showEditProgramModal(program) {
      this.editMode = true;
      this.form.fill(program);
      $('#add-program-modal').modal('show');
    },
    getPrograms() {
      axios
        .get('api/programs')
        .then((response) => {
          this.programs = response.data;
        })
        .catch((error) => {
          toastr.error(error, 'Information');
        });
    },

    getHeiPrograms() {
      this.tableLoader = true;
      axios
        .get('api/hei-programs/' + this.hei_data.code)
        .then((response) => {
          this.hei_programs = response.data;
          this.tableLoader = false;
        })
        .catch((error) => {
          toastr.error('Something went wrong', 'Request failed');
          this.tableLoader = false;
        });
    },

    addProgram() {
      let b = document.getElementById('add-program-btn');
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> INSERTING...`;
      b.disabled = true;

      axios
        .post('api/hei-programs', this.form)
        .then((response) => {
          toastr.success(response.data, 'Information');
          $('#add-program-modal').modal('hide');
          b.innerHTML = 'INSERT';
          b.disabled = false;
          this.getHeiPrograms();
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
          b.innerHTML = 'INSERT';
          b.disabled = false;
        });
    },
    editProgram() {
      let b = document.getElementById('edit-program-btn');
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> UPDATING...`;
      b.disabled = true;
      axios
        .put('api/hei-programs/' + this.form.hei_program_id, this.form)
        .then((response) => {
          toastr.success(response.data.msg, 'Information');
          $('#add-program-modal').modal('hide');
          b.innerHTML = 'UPDATE';
          b.disabled = false;
          this.getHeiPrograms();
        })
        .catch((error) => {
          toastr.error('Something went wrong!', 'Error');
          b.innerHTML = 'UPDATE';
          b.disabled = false;
        });
    },
    showAddProgramModal() {
      this.editMode = false;
      this.form.reset();
      $('#add-program-modal').modal('show');
    },
  },
  computed: {
    filteredHeiPrograms() {
      let temp = this.hei_programs;
      if (this.searchValue != '') {
        temp = temp.filter((program) => {
          return program['program_name']
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getHeiPrograms();
    this.getPrograms();
  },
  updated() {},
};
</script>

<style scoped>
#hei-programs-table tr {
  cursor: pointer !important;
}
</style>
