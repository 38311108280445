<template>
  <div>
    <div class="mb-3">
      <input
        type="search"
        class="form-control col-3"
        v-model="searchValue"
        placeholder="Search faculty name"
      />
    </div>

    <table class="table table-bordered table-sm table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Full Name</th>
          <th>Primary Teaching Discipline</th>
          <th>Program Name</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-center"
          v-if="!filteredFaculties.length && !tableLoader"
        >
          <td colspan="6">
            No results found
          </td>
        </tr>
        <tr class="text-center" v-if="tableLoader">
          <td colspan="11">
            <div class="spinner-border spinner-border-sm mr-1"></div>
            Loading
          </td>
        </tr>
        <tr v-for="(faculty, index) in filteredFaculties" :key="faculty.id">
          <td>{{ index + 1 }}</td>
          <td>{{ faculty.full_name }}</td>
          <td>{{ faculty.primary_teaching_disc }}</td>
          <td>{{ faculty.bs_program_name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HeiFaculty',
  data() {
    return {
      faculties: [],
      tableLoader: false,
      searchValue: '',
    };
  },
  methods: {
    getHeiFaculties() {
      this.tableLoader = true;
      axios
        .get('api/hei/faculties/' + this.$route.params.id)
        .then((res) => {
          this.faculties = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
  },
  computed: {
    filteredFaculties() {
      let temp = this.faculties;
      if (this.searchValue != '') {
        temp = temp.filter((faculty) => {
          return faculty['full_name']
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getHeiFaculties({ tableLoader: true });
  },
};
</script>
