<template>
  <div>
    <ul class="nav nav-tabs" id="program-monitoring-tabs">
      <li class="nav-item">
        <a
          class="nav-link disabled"
          id="MonitoringReports"
          @click.prevent="tabActive('MonitoringReports')"
          href="#"
          >Compliance History (Coming soon)</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link  active"
          id="ViewReports"
          href="#"
          @click.prevent="tabActive('ViewReports')"
          ><i class="bi bi-card-list mr-2"></i> View Reports</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="AddMonitoringReport"
          href="#"
          @click.prevent="tabActive('AddMonitoringReport')"
          ><i class="bi bi-plus-lg mr-2"></i>Add Monitoring Report</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="ListOfFindings"
          href="#"
          @click.prevent="tabActive('ListOfFindings')"
          ><i class="bi bi-list-check mr-2"></i>List of Findings/Observations</a
        >
      </li>
    </ul>
    <br />
    <component v-bind:is="currentTab"> </component>
  </div>
</template>

<script>
import MonitoringReports from './components/MonitoringReports.vue';
import AddMonitoringReport from './components/AddMonitoringReport.vue';
import ViewReports from './components/ViewReports.vue';
import ListOfFindings from './components/LisfOfFindings.vue';

export default {
  name: 'ProgramMonitoring',

  components: {
    MonitoringReports,
    AddMonitoringReport,
    ViewReports,
    ListOfFindings,
  },
  data() {
    return {
      currentTab: 'ViewReports',
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $('#program-monitoring-tabs .nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },
  },
};
</script>
