<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-8">
        <div class="form-group">
          <label>Area</label>
          <select
            name="mode-of-eval"
            v-model="area"
            class="form-control col-auto"
            @change="getInstitutionalRequirements({ tableLoader: true })"
            :disabled="tableLoader"
            required
          >
            <option value="" disabled hidden selected>Choose</option>
            <option v-for="(area, index) in areas" :key="index" :value="area">
              {{ area }}
            </option>
          </select>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-bordered table-hover">
            <thead>
              <th>
                REQUIREMENTS
              </th>
              <th>
                ADDED BY
              </th>
              <th>
                ADDED ON
              </th>
              <th>
                ACTIONS
              </th>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!reqs.length && !tableLoader">
                <td colspan="7">
                  No results found
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="11">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr v-for="areaData in processedReqs" :key="areaData.area.id">
                <template v-if="areaData.isGroupHeader">
                  <td colspan="4">
                    <strong class="text-uppercase"
                      >{{ String.fromCharCode(65 + areaData.headerIndex) }}.
                      {{ areaData.area.name }}</strong
                    >
                  </td>
                </template>

                <template v-else>
                  <td>
                    {{ areaData.indexInGroup + 1 }}.
                    {{ areaData.area.specific_requirement }}
                  </td>
                  <td>{{ areaData.area.added_by }}</td>
                  <td>{{ areaData.area.created_at | shortDate }}</td>

                  <td class="text-center px-0">
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      @click.prevent="deleteReq(areaData.area)"
                    >
                      <i class="bi bi-trash text-danger"></i>
                    </button>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-lg-12 col-xl-4">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="addInstituionalRequirement">
              <div class="card-title">
                <strong>Add or update a requirement</strong>
              </div>

              <div class="form-group">
                <label for="">Area of Monitoring</label>
                <select
                  name="mode-of-eval"
                  v-model="form.area"
                  class="form-control"
                  required
                >
                  <option value="" disabled hidden selected>Choose</option>
                  <option
                    v-for="(area, index) in areas"
                    :key="index"
                    :value="area"
                  >
                    {{ area }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="">General Requirement</label>
                <input
                  type="search"
                  class="form-control"
                  v-model="form.general_requirement"
                />
              </div>
              <div class="form-group">
                <label for="">Specific Requirement/s</label>

                <ol class="pl-3">
                  <li
                    v-for="(req, index) in form.specific_reqs"
                    class="mb-3"
                    :key="index"
                  >
                    <input
                      v-model="req.name"
                      type="search"
                      class="form-control"
                      required
                    />
                  </li>
                </ol>
              </div>

              <div class="form-inline mb-3">
                <div class="btn-group mr-2">
                  <button
                    class="btn btn-outline-secondary text-dark btn-sm"
                    @click.prevent="addSpecificReq"
                  >
                    <i class="bi bi-plus  icon-sm "></i>
                  </button>
                  <button
                    class="btn btn-outline-secondary text-dark btn-sm"
                    @click.prevent="deleteSpecificReq"
                  >
                    <i class="bi bi-dash  icon-sm"></i>
                  </button>
                </div>

                <span>Click to add or remove</span>
              </div>

              <div>
                <button
                  id="add-requirement-btn"
                  type="submit"
                  class="btn btn-primary mr-2"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstitutionalRequirements',
  data() {
    return {
      form: new Form({
        id: '',
        area: '',
        general_requirement: '',
        specific_reqs: [{ name: '' }],
      }),
      reqs: [],
      area: 'Governance and Management',
      areas: [
        'Administration and Non-Teaching Personnel',
        'CHED Philippine Anti-Illegal Drugs Strategy (PADS)',
        'Curriculum and SIPP',
        'Extension and Linkages',
        'Gender and Development',
        'Governance and Management',
        'Integration of Indigenous Peoples Studies/Education',
        'Integration of Peace Studies/Education',
        'Laboratories and Support Facilities',
        'Library',
        'National Service Training Program',
        'Research and Linkages',
        'Research, Extension, and Linkages',
        'Student Affairs Services',
        'Student Assistance and Services',
        'Teaching and Learning Modality',
      ],
      tableLoader: false,
    };
  },
  methods: {
    deleteReq(req) {
      if (
        !confirm(
          'This action cannot be undone. Are you sure you want to proceed?'
        )
      )
        return;

      axios
        .delete('api/hei/institutional-monitoring/requirements/' + req.id)
        .then((res) => {
          toastr.success(res.data.message, 'Info');
          this.getInstitutionalRequirements({ loader: false });
        })
        .catch((err) => {
          toastr.error('Unable to delete.', 'Server Error');
        });
    },
    getInstitutionalRequirements(option) {
      if (option.tableLoader) {
        this.reqs = [];
      }

      this.tableLoader = option.tableLoader;
      const encodedParameter = encodeURIComponent(this.area);
      axios
        .get('api/hei/institutional-monitoring/requirements', {
          params: {
            area: encodedParameter,
          },
        })
        .then((res) => {
          this.reqs = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
    addInstituionalRequirement() {
      let btn = document.getElementById('add-requirement-btn');
      btn.disabled = true;
      btn.innerHTML = 'Adding...';

      axios
        .post('api/hei/institutional-monitoring/requirements', this.form)
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'Add';
          toastr.success(res.data.message, 'Info');
          this.getInstitutionalRequirements({ tableLoader: false });
          this.form.reset();
          this.form.specific_reqs = [{ name: '' }];
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = 'Add';
        });
    },
    updateInstitutionalRequirement() {},
    deleteSpecificReq() {
      if (this.form.specific_reqs.length == 1) return;
      this.form.specific_reqs.pop();
    },
    addSpecificReq() {
      this.form.specific_reqs.push({
        name: '',
      });
    },
  },
  computed: {
    processedReqs() {
      let groupedAreas = {};

      this.reqs.forEach((area, index) => {
        const areaName = area.general_requirement;

        if (!(areaName in groupedAreas)) {
          groupedAreas[areaName] = [];
        }

        area.origIndex = index;
        groupedAreas[areaName].push(area);
      });

      //console.log(groupedAreas);

      let result = [];
      let headerIndex = 0;

      // Iterate through the grouped areas and create the result array
      for (const areaName in groupedAreas) {
        const areas = groupedAreas[areaName];

        result.push({
          area: { name: areaName }, // Group header
          isGroupHeader: true,
          headerIndex: headerIndex,
        });

        headerIndex++;

        areas.forEach((area, indexInGroup) => {
          result.push({
            area,
            indexInGroup,
            isGroupHeader: false,
          });
        });
      }

      return result;
    },
  },
  mounted() {
    this.getInstitutionalRequirements({ tableLoader: true });
  },
};
</script>
