<template>
  <div id="print-monitoring-report">
    <header class="text-center">
      <img
        src="@/assets/logo/header.png"
        width="500"
        height="132"
        alt="CHED Header Logo"
      />

      <img
        src="@/assets/images/bagong-pilipinas-logo.png"
        alt="Bagong Pilipinas Logo"
      />
    </header>

    <header class="text-center mt-3" style="font-size: 19px;">
      <h4 class="font-weight-bold mb-3">
        INSTITUTIONAL MONITORING REPORT
      </h4>
    </header>
    <table class="table table-sm" style="font-size: 19px;">
      <tbody>
        <tr>
          <th style="border: 1px solid black !important" colspan="4">
            REPORT DETAILS
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" width="20%">ID</td>
          <th style="border: 1px solid black !important">
            CHEDR13-IM-{{ report.id }}
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" width="20%">Area</td>
          <th style="border: 1px solid black !important" class="text-uppercase">
            {{ report.area }}
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">Name of HEI</td>
          <th style="border: 1px solid black !important" class="text-uppercase">
            {{ report.hei_name }}
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">Address</td>
          <th style="border: 1px solid black !important" class="text-uppercase">
            {{ `${report.street}, ${report.city}, ${report.province}` }}
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">Mode of Inspection</td>
          <th
            style="border: 1px solid black !important;"
            class="text-uppercase"
          >
            {{ report.mode }}
          </th>
        </tr>

        <tr>
          <td style="border: 1px solid black !important">Date of Monitoring</td>
          <th style="border: 1px solid black !important" class="text-uppercase">
            {{ report.date_of_inspection | standardDate }}
          </th>
        </tr>
      </tbody>
    </table>

    <table
      class="table table-sm"
      style="font-size: 19px; border-color: black !important;"
    >
      <thead class="text-center">
        <th style="border: 1px solid black !important" width="35%">
          AREAS/REQUIREMENTS
        </th>

        <th style="border: 1px solid black !important">ACTUAL SITUATION</th>
        <th style="border: 1px solid black !important" width="10%">
          COMPLIANCE
        </th>
      </thead>
      <tbody style="border-color: black !important;">
        <tr v-for="areaData in processedReqs" :key="areaData.area.id">
          <template v-if="areaData.isGroupHeader">
            <td colspan="6" style="border: 1px solid black !important;">
              <strong class="text-uppercase"
                >{{ getAlphabeticalCharacter(areaData.headerIndex) }}.
                {{ areaData.area.name }}</strong
              >
            </td>
          </template>

          <template v-else>
            <td
              style="border: 1px solid black !important; white-space: pre-line;"
              class="text-justify"
              width="48%"
            >
              {{ areaData.indexInGroup + 1 }}.
              {{ areaData.area.specific_requirement }}
            </td>

            <td style="border: 1px solid black !important" width="20%">
              <p style="white-space:pre-wrap">{{ areaData.area.remarks }}</p>
            </td>
            <td style="border: 1px solid black !important" class="text-center">
              <span v-if="areaData.area.is_complied" class="">Yes</span>
              <span v-else>No</span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <table class="table table-bordered mt-3" style="font-size: 19px;">
      <tbody>
        <tr>
          <td style="border: 1px solid black !important" colspan="4">
            Evaluated and validated by
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" width="60%">
            <strong class="mb-0 text-uppercase">{{
              getUserFullNameWithTitles
            }}</strong>
            <span class="form-text mt-0">
              {{
                $store.state.user.id == 9
                  ? 'Supervising Education Program Specialist'
                  : 'Education Supervisor II'
              }}</span
            >
          </td>
          <td style="border: 1px solid black !important" width="20%">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" colspan="4">
            Noted by
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0 invisible">HEI</strong>
            <span class="form-text mt-0 invisible">CEPS</span>
          </td>

          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0 invisible">HEI</strong>
            <span class="form-text mt-0 invisible">RD</span>
          </td>

          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" colspan="4">
            Conforme
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0 invisible">HEI</strong>
            <span class="form-text mt-0">HEI Representative</span>
          </td>

          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0">{{ report.president.toUpperCase() }}</strong>
            <span class="form-text mt-0">HEI President/Head</span>
          </td>
          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
      </tbody>
    </table>

    <p class="mt-3" style="font-size: 19px;">
      [CCMIS] Printed: {{ Date() | dateTime }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PrintReport',
  props: {
    report: {
      required: true,
    },
    reqs: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    print() {
      setTimeout(() => {
        this.$htmlToPaper('print-monitoring-report');
      });
    },
    getAlphabeticalCharacter(index) {
      return String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
    },
  },
  computed: {
    processedReqs() {
      let groupedAreas = {};

      this.reqs.forEach((area, index) => {
        const areaName = area.general_requirement;

        if (!(areaName in groupedAreas)) {
          groupedAreas[areaName] = [];
        }

        area.origIndex = index;
        groupedAreas[areaName].push(area);
        // console.log('This is the current area: ' + area);
      });

      // console.log(groupedAreas);

      let result = [];
      let headerIndex = 0;
      let rowIndex = 0;

      // Iterate through the grouped areas and create the result array
      for (const areaName in groupedAreas) {
        const areas = groupedAreas[areaName];

        result.push({
          area: { name: areaName }, // Group header
          isGroupHeader: true,
          headerIndex: headerIndex,
        });

        headerIndex++;

        areas.forEach((area, indexInGroup) => {
          result.push({
            area,
            indexInGroup,
            isGroupHeader: false,
            rowIndex: rowIndex,
          });

          rowIndex++;
        });
      }

      return result;
    },
    ...mapGetters(['getUserFullNameWithTitles']),
  },
};
</script>
