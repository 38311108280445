<template>
  <div id="main-container">
    <div class="mb-2">
      <a href="#" @click.prevent="$router.go(-1)" class="">
        <i class="bi bi-arrow-left-short icon-sm "></i> Go back
      </a>
    </div>

    <nav
      class="navbar navbar-expand-lg rounded navbar-light bg-white shadow-sm mb-4"
    >
      <div class="d-flex align-items-center">
        <img
          :src="hei_data.logo"
          class="my-auto mr-1"
          alt="School Logo"
          width="45"
          height="45"
        />
      </div>
      <a class="navbar-brand border-right pr-3 mr-0" href="#">
        <strong>{{ hei_data.abbrev }} </strong>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="hei-profile-nav">
        <ul id="nav-list" class="navbar-nav mr-auto">
          <li class="nav-item">
            <a
              id="HeiProfile_Dashboard"
              class="nav-link active border-right"
              href="#"
              @click.prevent="tabActive('HeiProfile_Dashboard')"
              >Profile</a
            >
          </li>
          <li class="nav-item">
            <a
              id="HeiPrograms"
              class="nav-link border-right"
              href="#"
              @click.prevent="tabActive('HeiPrograms')"
              >Recognized Programs</a
            >
          </li>
          <li class="nav-item">
            <a
              id="HeiProfile_Students"
              class="nav-link border-right"
              href="#"
              @click.prevent="tabActive('HeiProfile_Students')"
              >Enrollment Data</a
            >
          </li>
          <li class="nav-item">
            <a
              id="ProgramMonitoring"
              @click.prevent="tabActive('ProgramMonitoring')"
              class="nav-link border-right"
              href="#"
              >Program Monitoring</a
            >
          </li>
          <li class="nav-item">
            <a
              id="HeiInstitutionalMonitoring"
              @click.prevent="tabActive('HeiInstitutionalMonitoring')"
              class="nav-link border-right"
              href="#"
              >Institutional Monitoring</a
            >
          </li>
          <li class="nav-item">
            <a
              id="HeiFaculty"
              class="nav-link border-right"
              href="#"
              @click.prevent="tabActive('HeiFaculty')"
              >Faculty</a
            >
          </li>

          <li class="nav-item">
            <a
              id="HeiTosf"
              class="nav-link border-right px-3"
              href="#"
              @click.prevent="tabActive('HeiTosf')"
              >TOSF</a
            >
          </li>
        </ul>
      </div>
    </nav>

    <div class="card shadow-sm rounded">
      <div class="card-body">
        <component v-bind:is="currentTab" v-bind:hei_data="hei_data">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import HeiPrograms from './HeiPrograms.vue';
import HeiProfile_Dashboard from './HeiProfile_Dashboard.vue';
import HeiProfile_Students from './HeiProfile_Students.vue';
import ProgramMonitoring from './ProgramMonitoring/Main.vue';
import HeiFaculty from './HeiFaculty.vue';
import HeiTosf from './HeiTosf.vue';
import HeiInstitutionalMonitoring from './InstitutionalMonitoring/Main.vue';

export default {
  name: 'HeiProfile',
  props: ['hei_data'],
  components: {
    HeiProfile_Dashboard,
    HeiPrograms,
    HeiProfile_Students,
    ProgramMonitoring,
    HeiFaculty,
    HeiTosf,
    HeiInstitutionalMonitoring,
  },
  data() {
    return {
      currentTab: 'HeiProfile_Dashboard',
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $('.nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },
    previousRoute() {
      this.$router.go(-1);
    },
  },

  mounted() {
    //this.$store.state.sidebarMinimize = true;
  },
};
</script>
