<template>
  <div>
    <!--------------------- UPLOAD CSV FORM -------------------------->
    <form id="upload-csv-form" @submit.prevent="storeCSVtoDatabase">
      <div class="form-row">
        <div class="form-group col">
          <label for="">Program Name</label>
          <select
            v-model="otherData.selectedProgram"
            class="form-control"
            @change="storeSelectedProgramName"
            required
          >
            <option value="" selected disabled hidden>Select program </option
            ><option
              v-for="hei_program in hei_programs"
              :key="hei_program.hei_program_id"
              :value="hei_program.program_id"
            >
              {{ hei_program.program_name }}</option
            >
          </select>
        </div>
        <div class="form-group col">
          <label for="">Academic Year (AY)</label>
          <select
            v-model="otherData.selectedAcademicYear"
            class="form-control"
            required
            @change="storeSelectedAcademicYearName"
          >
            <option value="" selected disabled hidden
              >Select academic year</option
            >
            <option
              v-for="academic_year in academic_years"
              :key="academic_year.id"
              :value="academic_year.id"
            >
              {{ academic_year.academic_year }}
            </option>
          </select>
        </div>

        <div class="form-group col">
          <label for="">Semester</label>
          <select
            v-model="otherData.selectedSemester"
            class="form-control"
            @change="storeSelectedSemesterName"
            required
          >
            <option value="" selected disabled hidden>Select a semester</option>
            <option
              v-for="semester in semesters"
              :key="semester.id"
              :value="semester.id"
            >
              {{ semester.semester }}
            </option>
          </select>
        </div>

        <div class="form-group col">
          <label for="">Upload file</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="file-upload"
              @change="uploadCSV"
              accept=".csv"
              name="file-upload"
              required
            />
            <label class="custom-file-label" for="customFile"
              >Choose file</label
            >
          </div>
        </div>
      </div>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          id="inlineRadio1"
          value="EL"
          name="inlineRadioOptions"
          v-model="otherData.type"
          required
        />
        <label class="form-check-label" for="inlineRadio1"
          >Enrollment List</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio2"
          v-model="otherData.type"
          value="PR"
          required
        />
        <label class="form-check-label" for="inlineRadio2"
          >Promotional Report</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio3"
          value="LG"
          v-model="otherData.type"
          required
        />
        <label class="form-check-label" for="inlineRadio3"
          >List of Graduates</label
        >
      </div>

      <div id="save-btn-section" class="mt-3">
        <button
          v-show="!validationErrors.length"
          type="submit"
          id="save-to-database-btn"
          class="btn btn-primary"
        >
          Upload to database
        </button>
      </div>
    </form>

    <div id="validation-errors" class="mt-3">
      <div
        v-if="validationErrors.length != 0"
        class="alert alert-danger alert-dismissible"
      >
        <b>{{
          `Oops! Found ( ${validationErrors.length}) validation error(s) on
          the file submitted. [${selectedProgramName}- ${selectedSemesterName} - ${selectedAcademicYearName} - ${otherData.type}] `
        }}</b>

        <p>
          Please double check and address the following to proceed:
        </p>
        <ol class="mt-2">
          <li v-for="error in validationErrors" :key="error">
            {{ error }}
          </li>
        </ol>

        <button class="btn btn-link" @click="printValidationErrors">
          <i class="bi bi-printer-fill mr-1"></i> Print
        </button>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <!---------------- // UPLOAD CSV FORM -------------------------------->

    <!---------------- Uploaded CSV Table  -------------------------------->
    <div v-if="showTable" class="">
      <hr class="dotted" />

      <div class="">
        <div class="">
          <div>
            <div class="alert alert-warning" role="alert">
              IMPORTANT NOTE: Please check the list before uploading to the
              database.
              <br />Total Students: <b>{{ students.length }}</b>
            </div>
            <!--------------------- Uploaded CSV Table --------------------->

            <div class="input-group col-4 px-0 mb-4">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="bi bi-search"></i
                ></span>
              </div>

              <input
                v-model="searchValue"
                type="text"
                class="form-control"
                placeholder="Search Last Name, First Name, School ID and etc.."
              />
            </div>

            <span
              >NOTE: Columns with <b class="text-danger mr-1">*</b>must not be
              blank.</span
            >

            <table id="list-of-students" class="table table-bordered mt-2">
              <thead>
                <tr>
                  <th>#</th>
                  <th><span class="text-danger mr-1">*</span>ID Number</th>
                  <th><span class="text-danger mr-1">*</span>Last Name</th>
                  <th><span class="text-danger mr-1">*</span>First Name</th>
                  <th>Middle Name</th>
                  <th>Date of Birth</th>
                  <th><span class="text-danger mr-1">*</span>Sex</th>
                  <th><span class="text-danger mr-1">*</span>Address</th>
                  <th><span class="text-danger mr-1">*</span>Year Level</th>
                  <th width="10%">
                    <span class="text-danger mr-1">*</span>Course Code
                  </th>
                  <th width="20%">
                    <span class="text-danger mr-1">*</span>Course Description
                  </th>
                  <th>Grades</th>
                  <th><span class="text-danger mr-1">*</span>Units</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(student, index) in filteredStudents" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ student[1] }}</td>
                  <td>{{ student[2] }}</td>
                  <td>{{ student[3] }}</td>
                  <td>{{ student[4] }}</td>
                  <td>{{ student[5] }}</td>
                  <td>{{ student[6] }}</td>
                  <td>{{ student[7] }}</td>

                  <td>{{ student[8] }}</td>
                  <td style="white-space: pre-wrap;">
                    {{ displayJSON(student[9]) }}
                  </td>
                  <td style="white-space: pre-wrap;">
                    {{ displayJSON(student[10]) }}
                  </td>
                  <td style="white-space: pre-wrap;">
                    {{ displayJSON(student[11]) }}
                  </td>
                  <td style="white-space: pre-wrap;">
                    {{ displayJSON(student[12]) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!---------------- // Uploaded CSV Table -------------------------------->
  </div>
  <!-- // root container -->
</template>

<script>
import Papa from 'papaparse';

export default {
  props: ['hei_programs'],
  data() {
    return {
      searchValue: '',
      removedStudents: [],
      validationErrors: [],
      students: '',
      showTable: false,
      semesters: '',
      academic_years: '',
      otherData: {
        selectedProgram: '',
        selectedAcademicYear: '',
        selectedSemester: '',
        hei_code: this.$attrs['hei_data'].code,
        type: null,
      },
      selectedProgramName: '',
      selectedAcademicYearName: '',
      selectedSemesterName: '',
    };
  },
  methods: {
    printValidationErrors() {
      setTimeout(() => {
        this.$htmlToPaper('validation-errors');
      }, 1000);
    },
    storeSelectedAcademicYearName() {
      this.academic_years.find((ay) => {
        if (ay.id == this.otherData.selectedAcademicYear) {
          this.selectedAcademicYearName = ay.academic_year;
          return;
        }
      });
    },
    storeSelectedSemesterName() {
      this.semesters.find((semester) => {
        if (semester.id == this.otherData.selectedSemester) {
          this.selectedSemesterName = semester.semester;
          return;
        }
      });
    },
    storeSelectedProgramName() {
      this.hei_programs.find((program) => {
        if (program.program_id == this.otherData.selectedProgram) {
          this.selectedProgramName = program.program_name;
          return;
        }
      });
    },
    getAcademicYears() {
      axios
        .get('api/academic-years')
        .then((response) => {
          this.academic_years = response.data;
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
        });
    },
    getSemesters() {
      axios
        .get('api/semesters')
        .then((response) => {
          this.semesters = response.data;
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
        });
    },

    storeCSVtoDatabase() {
      let uploadButton = document.getElementById('save-to-database-btn');
      uploadButton.innerHTML = `<div class="spinner-border spinner-border-sm mr-1" role="status"></div> Processing...`;
      uploadButton.disabled = true;

      axios
        .post('api/upload-csv', {
          data: {
            students: this.students,
            otherData: this.otherData,
          },
        })
        .then((response) => {
          uploadButton.innerHTML = 'Upload to database';
          uploadButton.disabled = false;
          this.removedStudents = response.data.removed_studs;

          toastr.success(
            'Saved data successfully! Total students inserted: ' +
              response.data,
            'Information'
          );

          this.students = [];
          this.showTable = false;
          this.otherData.selectedAcademicYear = '';
          this.otherData.selectedSemester = '';
          this.otherData.selectedProgram = '';
          this.validationErrors = [];
          document.getElementById('upload-csv-form').reset();
          document.getElementById('file-upload').value = '';
          $('.custom-file-label').html('Choose file');
        })
        .catch((error) => {
          uploadButton.innerHTML = 'Upload to database';
          uploadButton.disabled = false;
          this.validationErrors = error.response.data;
        });
    },

    displayJSON(json) {
      if (json === null || json === undefined) {
        return 0;
      }

      if (Array.isArray(json)) {
        let json_string = json.join('\n ');
        return json_string;
      } else return json;
    },

    processCSV(csv) {
      //console.log('CSV DATA:', csv);

      this.students = [];

      var temp_course_codes = [];
      var temp_course_desc = [];
      var temp_course_grades = [];
      var temp_course_units = [];
      var student_row = 0;
      var x = 1;

      //check CSV file if not empty
      if (csv.length != 0) {
        // console.log('CSV is not empty!');

        for (var i = 0; i < csv.length; i++) {
          // console.log('Current iteration: ', i);
          // console.log('Current Temp Grades: ', temp_course_grades);
          //check if row has a student
          if (csv[i][1] != '') {
            //naa ba student
            // console.log('A student record has been found.');

            if (i == 0) {
              // console.log('This is the first student in the list');
              this.students.push(csv[i]); //students = [ ACIDO JANE ANN ]

              temp_course_codes.push(csv[i][9]); // temp_course_code = [ MIT 505 ]
              temp_course_desc.push(csv[i][10]); // temp_course_desc = [ Extension ]
              temp_course_grades.push(csv[i][11]);
              temp_course_units.push(csv[i][12]); // units = [3]
              /* if (csv[i][11] != '') {
                //console.log('Pushing grades to array!');
                temp_course_grades.push(csv[i][11]);
              } */
            } else {
              //console.log('Found a new student. Time to empty the arrays.');
              let new_temp_course_codes = temp_course_codes.slice();
              this.students[student_row][9] = new_temp_course_codes;
              // console.log(temp_course_codes);

              let new_temp_course_desc = temp_course_desc.slice();
              this.students[student_row][10] = new_temp_course_desc;
              // console.log(temp_course_desc);

              if (temp_course_grades) {
                let new_temp_course_grades = temp_course_grades.slice();
                this.students[student_row][11] = new_temp_course_grades;
                // console.log(temp_course_grades);
                temp_course_grades.length = 0;
              }

              let new_temp_course_units = temp_course_units.slice();
              this.students[student_row][12] = new_temp_course_units;
              // console.log(temp_course_units);
              student_row++;

              temp_course_codes.length = 0;
              temp_course_desc.length = 0;
              temp_course_units.length = 0;

              this.students.push(csv[i]);

              temp_course_codes.push(csv[i][9]); // temp_course_code = [ MIT 505 ]
              temp_course_desc.push(csv[i][10]); // temp_course_code = [ Extension ]
              temp_course_units.push(csv[i][12]);
              temp_course_grades.push(csv[i][11]);
              /* if (csv[i][11] != '') {
                temp_course_grades.push(csv[i][11]);
              } */
            }
          } else if (csv[i][2] !== '' && csv[i][1] === '') {
            this.validationErrors.push(
              'Missing School ID number. Try to check the CSV file and make sure that ID column must not be blank. Student: ' +
                csv[i][2] +
                ', ' +
                csv[i][3]
            );
          } else if (csv[i][1] == '') {
            // console.log('Fetching additional courses.');
            temp_course_codes.push(csv[i][9]);
            temp_course_desc.push(csv[i][10]);
            temp_course_units.push(csv[i][12]);
            temp_course_grades.push(csv[i][11]);
            /*  if (csv[i][11] != '') {
              // console.log('Pushing additional grades!');
              temp_course_grades.push(csv[i][11]);
            } */
          }
        }

        this.students[student_row][9] = temp_course_codes;
        this.students[student_row][10] = temp_course_desc;
        this.students[student_row][12] = temp_course_units;
        this.students[student_row][11] = temp_course_grades;
        /*  if (temp_course_grades) {
          this.students[student_row][11] = temp_course_grades;
        } */
      }

      this.showTable = true;
    },
    uploadCSV(event) {
      let _this = this;

      const csv = event.target.files[0];
      const fileName = event.target.files[0].name;
      $('.custom-file-label').html(fileName);

      // console.log('This is the CSV: ', csv);

      Papa.parse(csv, {
        complete: function(results) {
          this.csvData = results.data;
          // console.log(this.csvData);
          _this.processCSV(this.csvData.slice(7));
        },
        skipEmptyLines: true,
        header: false,
      });
    },
  },
  computed: {
    filteredStudents() {
      let tempStudents = this.students;

      if (this.searchValue != '') {
        tempStudents = tempStudents.filter((student) => {
          return (
            student[2].toUpperCase().includes(this.searchValue.toUpperCase()) ||
            student[3].toUpperCase().includes(this.searchValue.toUpperCase()) ||
            student[1].toUpperCase().includes(this.searchValue.toUpperCase())
          );
        });
      }

      return tempStudents;
    },
  },
  mounted() {
    this.getSemesters();
    this.getAcademicYears();
  },
};
</script>
