<template>
  <div>
    <div>
      <h5 class="mb-1">Tuition and Other School Fees Increase</h5>
      <p>
        <a
          href="https://drive.google.com/file/d/1HDCLDsX_k8g2-7jVuLc0Vx9yFDSudkrl/view?usp=sharing"
          target="_blank"
          >CMO No. 3, s. 2012</a
        >
        : “Enhanced Policies, Guidelines and Procedures Governing Increases in
        Tuition and Other School Fees, Introduction of New Fees, and For Other
        Purposes”
      </p>
    </div>
    <div>
      <table
        id="tosf-apps-table"
        class="table table-hover table-sm table-bordered"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>App No.</th>
            <th>HEI</th>
            <th>Date and Time Submitted</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-if="!apps.length && !tableLoader">
            <td colspan="11">
              No results found
            </td>
          </tr>
          <tr class="text-center" v-if="tableLoader">
            <td colspan="11">
              <div class="spinner-border spinner-border-sm mr-1"></div>
              Loading
            </td>
          </tr>
          <tr
            @click.stop="openApp(app)"
            v-for="(app, index) in apps"
            :key="app.id"
          >
            <td>{{ index + 1 }}</td>
            <th>CHED13-TOSF-{{ app.id }}</th>
            <td>{{ app.name }}</td>
            <td>{{ app.created_at | completeDateTime }}</td>
            <td><span class="badge badge-danger">Pending</span></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal fade" id="view-tosf-app-modal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container pt-3">
              <div class="text-center mb-3">
                <h5 class="mb-1">TUITION AND OTHER SCHOOL FEES INCREASE</h5>
                <h4 class="text-center font-weight-bold">CHECKLIST FORM</h4>
              </div>

              <div class="">
                <p class="mb-0">
                  App No: <strong>CHED13-TOSF-{{ app.id }}</strong>
                </p>
                <p class="mb-0">
                  HEI Name:
                  <strong>{{ app.name }}</strong>
                </p>
                <p>
                  Date & Time Submitted:
                  <strong>{{ app.created_at | completeDateTime }}</strong>
                </p>
              </div>

              <div v-if="docReqsLoader" class="text-center">
                <div
                  class="spinner-border spinner-border-sm ml-auto"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>

              <table v-else class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th width="40%">Documentary Requirements</th>
                    <th>File</th>
                    <th>Compliance</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(req, index) in reqs" :key="req.id">
                    <td>
                      {{ `${index + 1}. ${req.description}` }}
                    </td>
                    <td>
                      <a href="#" @click.prevent="openUrl(req.path)">
                        {{ req.filename }}
                      </a>
                    </td>
                    <td><input type="checkbox" disabled /></td>
                    <td>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="3"
                        class="form-control"
                        disabled
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeiTosf',
  data() {
    return {
      apps: [],
      reqs: [],
      app: {},
      tableLoader: false,
      docReqsLoader: false,
      prefix: 'https://drive.google.com/file/d/',
    };
  },
  methods: {
    openApp(app) {
      $('#view-tosf-app-modal').modal('show');
      this.app = app;
      this.getAppDocReqs(this.app.id);
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    getAppDocReqs(appId) {
      this.docReqsLoader = true;

      axios
        .get('api/tosf/documentary-requirements/' + appId)
        .then((res) => {
          this.reqs = res.data;
          this.docReqsLoader = false;
        })
        .catch((err) => {
          this.docReqsLoader = false;
        });
    },
    getHeiTosfApps() {
      this.tableLoader = true;

      axios
        .get('api/tosf/applications/' + this.$attrs.hei_data.code)
        .then((res) => {
          this.apps = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          toastr.error('Oops! Something went wrong.', 'Error');
        });
    },
  },
  mounted() {
    this.getHeiTosfApps();
  },
};
</script>

<style scoped>
#tosf-apps-table tr {
  cursor: pointer !important;
}
</style>
