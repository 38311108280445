<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <form @submit.prevent="createInitialReport">
        <div class="form-row">
          <div class="col-auto">
            <div class="form-group">
              <label for="">Program</label>
              <select
                name=""
                id=""
                class="form-control"
                required
                v-model="reportDetails.program_id"
                :disabled="!hei_programs.length"
              >
                <option value="" selected hidden disabled>Choose</option>
                <option
                  v-for="hei_program in hei_programs"
                  :key="hei_program.hei_program_id"
                  :value="hei_program.program_id"
                  >{{ hei_program.program_name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="">Year</label>
              <select
                name=""
                id=""
                class="form-control"
                required
                v-model="reportDetails.academic_year"
              >
                <option value="" selected hidden disabled>Choose</option>
                <option value="2025-2026">2025-2026</option>
                <option value="2024-2025">2024-2025</option>
                <option value="2023-2024">2023-2024</option>
                <option value="2022-2023">2022-2023</option>
                <option value="2021-2022">2021-2022</option>
                <option value="2020-2021">2020-2021</option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="">Mode</label>
              <select
                name=""
                id=""
                class="form-control"
                required
                v-model="reportDetails.mode_of_inspection"
              >
                <option value="" selected hidden disabled>Choose</option>
                <option value="Onsite">Onsite</option>
                <option value="Virtual">Virtual</option>
                <option value="Desktop">Desktop</option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="">Date</label>
              <input
                type="date"
                class="form-control"
                v-model="reportDetails.date_of_inspection"
                required
              />
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="" class="invisible">Button</label>
              <button
                id="submit-report-btn"
                type="submit"
                class="btn btn-primary form-control"
                style="background-color:#321fdb; color: #fff"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="">
        <button
          class="btn btn-outline-secondary text-dark"
          @click.prevent="openAddFindingModal"
        >
          <i class="bi bi-plus-lg mr-2"></i>Add Findings/Observations
        </button>
      </div>
    </div>

    <template v-if="processedProgramAreas.length">
      <hr />
      <div class="mb-3">
        <h5>
          Report ID: <strong>CHEDR13-PM-{{ report.id }}</strong>
        </h5>
      </div>
      <div class="mt-2  table-responsive">
        <table class="table  table-bordered table-sm ">
          <thead class="text-center">
            <th width="30%">AREAS / REQUIREMENTS</th>
            <th width="25%">FINDINGS / OBSERVATIONS</th>
            <th>COMPLIANCE</th>
            <th width="35%">REMARKS</th>
            <th>ACTION</th>
          </thead>
          <tbody>
            <tr class="text-center" v-if="!processedProgramAreas.length">
              <td colspan="7">No area(s) added yet.</td>
            </tr>
            <tr
              v-for="areaData in processedProgramAreas"
              :key="areaData.area.id"
            >
              <template v-if="areaData.isGroupHeader">
                <td colspan="6">
                  <strong class="text-uppercase"
                    >{{ getAlphabeticalCharacter(areaData.headerIndex) }}.
                    {{ areaData.area.name }}</strong
                  >
                </td>
              </template>
              <template v-else>
                <td style="white-space:pre-line;">
                  {{ areaData.indexInGroup + 1 }}.
                  {{ areaData.area.cmo_requirements }}
                </td>

                <td>
                  <ul class="pl-4 pr-0" v-if="!areaData.area.editable">
                    <li
                      v-for="(finding, index) in areaData.area.findings"
                      :key="index"
                    >
                      {{ `${finding.code} - ${finding.description}` }}
                    </li>
                  </ul>
                </td>
                <td class="text-center">
                  <span
                    v-if="areaData.area.is_complied"
                    class="font-weight-bold"
                    >&#10003;</span
                  >
                </td>
                <td>
                  <p style="white-space: pre-wrap">
                    {{ areaData.area.remarks }}
                  </p>
                </td>
                <td class="text-center">
                  <button
                    class=" btn btn-link btn-sm"
                    @click.prevent="
                      openAreaEvaluationModal(
                        areaData.area,
                        areaData.indexInGroup
                      )
                    "
                  >
                    <i class="bi bi-pencil-fill text-primary"></i>
                  </button>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="font-weight-bold">GENERAL REMARKS/RECOMMENDATIONS</p>
      <p style="white-space: pre-wrap;">{{ report.general_remarks }}</p>
      <div v-if="!genRemarksEditable" @click.prevent="editGenRemarks">
        <button class="btn btn-link">
          <i class="bi bi-pencil-fill mr-2"></i> Add/Edit General Remarks
        </button>
      </div>

      <div class="form-group mt-3" v-else>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control"
          v-model="generalRemarks"
        ></textarea>
      </div>

      <div>
        <button
          class="btn btn-link"
          v-if="genRemarksEditable"
          @click.prevent="saveGenRemarks"
        >
          <i class="bi bi-save-fill text-success mr-2"></i> Save remarks
        </button>

        <div
          v-if="saveGenRemarksLoader"
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="mt-4">
        <button class="btn btn-success mr-3" @click.prevent="print">
          <i class="bi bi-printer-fill mr-2"></i>Print/Save as PDF
        </button>
        <button
          class="btn btn-outline-secondary text-dark"
          @click.prevent="exportToCSV"
        >
          <i class="bi bi-exclamation-circle-fill mr-2"></i>Save Entries to
          Local File
        </button>
      </div>
    </template>

    <AddFindingModal
      ref="AddFindingModal"
      :areas="areas"
      v-on:addFinding="getFindings"
    ></AddFindingModal>

    <PrintReport
      v-show="false"
      ref="PrintReport"
      :areas="programAreas"
      :report="report"
    >
    </PrintReport>

    <AreaEvaluationModal
      ref="AreaEvaluationModal"
      v-on:getAreaEvaluations="
        getAreaEvaluations(report.id, { loaderEnabled: false })
      "
    ></AreaEvaluationModal>
  </div>
</template>

<script>
import AddFindingModal from './AddFindingModal.vue';
import Papa from 'papaparse';
import PrintReport from './PrintReport.vue';
import AreaEvaluationModal from './AreaEvaluationModal.vue';

export default {
  name: 'AddMonitoringReport',
  components: {
    AddFindingModal,
    PrintReport,
    AreaEvaluationModal,
  },
  data() {
    return {
      value: '',
      hei_programs: [],
      a_val: [],
      options: [],
      programAreas: [],
      selectedAreas: [],
      currentArea: '',
      findings: [],
      areas: [],
      loader: false,
      checkbox: false,
      genRemarksEditable: false,
      reportDetails: {
        hei_code: this.$route.params.id,
        program_id: '',
        academic_year: '',
        mode_of_inspection: '',
        date_of_inspection: '',
        general_remarks: '',
        is_complied: false,
      },
      report: {
        president: '',
      },
      generalRemarks: '',
      saveGenRemarksLoader: false,
      form: new Form({
        id: '',
        is_complied: '',
        remarks: '',
        findings: [],
        index: '',
      }),
    };
  },
  methods: {
    print() {
      this.$refs.PrintReport.print();
    },
    openAreaEvaluationModal(area, indexInGroup) {
      this.$refs.AreaEvaluationModal.open(area, indexInGroup);
    },
    saveGenRemarks() {
      this.saveGenRemarksLoader = true;
      axios
        .put(
          'api/program-monitoring-reports/general-remarks/' + this.report.id,
          { generalRemarks: this.generalRemarks }
        )
        .then((res) => {
          this.genRemarksEditable = false;
          this.report = res.data;
          this.report.refs = JSON.parse(res.data.refs);
          this.saveGenRemarksLoader = false;
        })
        .catch((err) => {
          this.saveGenRemarksLoader = false;
        });
    },

    editGenRemarks() {
      this.genRemarksEditable = true;
    },
    exportToCSV() {
      const csv = Papa.unparse(this.programAreas);
      this.downloadCSV(csv, 'program_monitoring_backup.csv');
    },
    downloadCSV(csv, filename) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        // Other browsers
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    getHeiPrograms() {
      axios
        .get('api/hei-programs/' + this.$route.params.id)
        .then((response) => {
          this.hei_programs = response.data;
          this.getFindings();
        })
        .catch((error) => {
          toastr.error('Something went wrong', 'Request failed');
        });
    },

    createInitialReport() {
      let b = document.getElementById('submit-report-btn');
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Creating...`;
      b.disabled = true;
      axios
        .post('api/program-monitoring-reports', {
          data: this.reportDetails,
        })
        .then((res) => {
          this.report = res.data.report;
          this.report.refs = JSON.parse(res.data.report.refs);
          this.getAreaEvaluations(this.report.id, { loaderEnabled: true });
        })
        .catch((err) => {
          toastr.error('Unable to create report.', 'Check internet connection');
          b.innerHTML = `Create`;
          b.disabled = false;
        });
    },

    getAreaEvaluations(reportId, option) {
      let b = document.getElementById('submit-report-btn');
      this.loader = option.loaderEnabled;

      axios
        .get('api/program-monitoring-reports/areas-to-monitor/' + reportId)
        .then((res) => {
          if (!res.data.length) {
            toastr.error('No areas have been added yet.', 'Error');
            return;
          }
          this.programAreas = res.data.map((obj) => {
            return {
              ...obj,
              options: this.findings.filter((opt) => {
                return opt.area_id == obj.area_id;
              }),
            };
          });

          b.innerHTML = `Create`;
          b.disabled = false;
        })
        .catch((err) => {
          toastr.error('Unable to fetch areas.', 'Oops! Something went wrong.');
          this.loader = false;
        });
    },

    getFindings() {
      axios.get('api/findings').then((res) => {
        this.findings = res.data;
        if (this.programAreas.length) {
          this.programAreas.forEach((area) => {
            area.options = this.findings.filter((opt) => {
              return opt.area_id == area.area_id;
            });
          });
        }
      });
    },

    getAlphabeticalCharacter(index) {
      return String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
    },

    getAreas() {
      axios.get('api/areas').then((res) => {
        this.areas = res.data;
      });
    },

    openAddFindingModal() {
      this.$refs.AddFindingModal.open();
    },
  },
  computed: {
    processedProgramAreas() {
      let groupedAreas = {};

      this.programAreas.forEach((area, index) => {
        const areaName = area.name;

        if (!(areaName in groupedAreas)) {
          groupedAreas[areaName] = [];
        }

        area.origIndex = index;
        groupedAreas[areaName].push(area);
      });

      let result = [];
      let headerIndex = 0;

      // Iterate through the grouped areas and create the result array
      for (const areaName in groupedAreas) {
        const areas = groupedAreas[areaName];

        result.push({
          area: { name: areaName }, // Group header
          isGroupHeader: true,
          headerIndex: headerIndex,
        });

        headerIndex++;

        areas.forEach((area, indexInGroup) => {
          result.push({
            area,
            indexInGroup,
            isGroupHeader: false,
          });
        });
      }

      return result;
    },
  },
  mounted() {
    this.getHeiPrograms();
    this.getAreas();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
