<template>
  <div>
    <div class="modal fade" data-backdrop="static" id="area-evaluation-modal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container py-3">
              <div class="mb-3">
                <h5 class="font-weight-bold">{{ area.name }}</h5>
              </div>

              <p style="white-space:pre-line;">
                {{ indexInGroup + 1 }}. {{ area.cmo_requirements }}
              </p>

              <div class="form-group">
                <label for="" class="font-weight-bold"
                  >Findings/Observations</label
                >
                <Multiselect
                  :options="area.options"
                  :custom-label="codeWithDesc"
                  track-by="description"
                  v-model="area.findings"
                  placeholder="Choose"
                  :searchable="true"
                  :multiple="true"
                ></Multiselect>
              </div>

              <div class="form-group">
                <label for="" class="font-weight-bold">Compliance</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    style="width: 16px; height: 16px;"
                    v-model="area.is_complied"
                  />
                  <label class="form-check-label ml-2" for="defaultCheck1">
                    Check to confirm compliance
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label for="" class="font-weight-bold">Remarks</label>
                <textarea
                  v-model="area.remarks"
                  class="form-control"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-link text-dark mr-2"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  :disabled="loader"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="save"
                >
                  <span v-if="!loader">Save</span>
                  <span v-else class="spinner-border spinner-border-sm"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'AreaEvaluationModal',
  components: {
    Multiselect,
  },
  data() {
    return {
      area: new Form({
        id: '',
        is_complied: '',
        remarks: '',
        options: [],
        name: '',
        remarks: '',
        findings: [],
        cmo_requirements: '',
      }),
      loader: false,
      indexInGroup: null,
    };
  },
  methods: {
    open(area, indexInGroup) {
      $('#area-evaluation-modal').modal('show');
      this.area.fill(area);
      this.indexInGroup = indexInGroup;
    },
    codeWithDesc({ code, description }) {
      return `${code} - ${description}`;
    },
    save() {
      this.loader = true;
      axios
        .put('api/program-monitoring-reports/' + this.area.id, this.area)
        .then((res) => {
          this.$emit('getAreaEvaluations');
          $('#area-evaluation-modal').modal('hide');
          this.loader = false;
        })
        .catch((err) => {
          toastr.error('Oops! Unable to fetch areas.', 'Server Error');
          this.loader = false;
        });
    },
  },
};
</script>
