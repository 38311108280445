<template>
  <div>
    <div class="table-responsive">
      <table
        id="monitoring-reports-table"
        class="table table-sm table-hover table-bordered"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>REPORT ID</th>
            <th>HEI</th>
            <th>AREA</th>
            <th>MODE</th>
            <th>DATE OF MONITORING</th>
            <th>MONITORED BY</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-if="!reports.length && !tableLoader">
            <td colspan="7">
              No results found
            </td>
          </tr>
          <tr class="text-center" v-if="tableLoader">
            <td colspan="11">
              <div class="spinner-border spinner-border-sm mr-1"></div>
              Loading...
            </td>
          </tr>
          <tr
            v-for="(report, index) in reports"
            :key="report.id"
            @click.prevent="openReportModal(report)"
          >
            <td>{{ index + 1 }}</td>
            <td>CHEDR13-IM-{{ report.id }}</td>
            <td>{{ report.hei_name }}</td>
            <td>{{ report.area }}</td>
            <td>{{ report.mode }}</td>
            <td>{{ report.date_of_monitoring | standardDate }}</td>
            <td>{{ report.monitored_by }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal fade" id="view-report-modal">
      <div class="modal-dialog" style="max-width:90%" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mb-3">
              <button class="btn btn-primary" @click.prevent="print">
                <i class="bi bi-printer-fill mr-2"></i>Print
              </button>
            </div>
            <div class="responsive">
              <table class="table table-sm table-bordered">
                <tbody>
                  <tr>
                    <td colspan="6">
                      <h5 class="font-weight-bold mb-0">
                        INSTITUTIONAL MONITORING
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">Report ID</td>
                    <th colspan="5">CHEDR13-IM-{{ report.id }}</th>
                  </tr>
                  <tr>
                    <td>HEI</td>
                    <th colspan="5" class="text-uppercase">
                      {{ report.hei_name }}
                    </th>
                  </tr>
                  <tr>
                    <td>Area</td>
                    <th colspan="5" class="text-uppercase">
                      {{ report.area }}
                    </th>
                  </tr>
                  <tr>
                    <td>Mode</td>
                    <th colspan="5" class="text-uppercase">
                      {{ report.mode }}
                    </th>
                  </tr>
                  <tr>
                    <td>Date of Monitoring</td>
                    <th colspan="5" class="text-uppercase">
                      {{ report.date_of_monitoring | standardDate }}
                    </th>
                  </tr>
                  <tr>
                    <td>Monitored by</td>
                    <th colspan="5" class="text-uppercase">
                      {{ report.monitored_by }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th>REQUIREMENTS</th>

                    <th>ACTUAL SITUATION</th>
                    <th>COMPLIANCE</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="areaData in processedReqs" :key="areaData.area.id">
                    <template v-if="areaData.isGroupHeader">
                      <td colspan="4">
                        <strong class="text-uppercase"
                          >{{ String.fromCharCode(65 + areaData.headerIndex) }}.
                          {{ areaData.area.name }}</strong
                        >
                      </td>
                    </template>

                    <template v-else>
                      <td width="50%">
                        {{ areaData.indexInGroup + 1 }}.
                        {{ areaData.area.specific_requirement }}
                      </td>

                      <td>
                        <span
                          v-if="areaData.area.remarks"
                          v-show="!areaData.area.editable"
                          >{{ areaData.area.remarks }}</span
                        >
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="5"
                          class="form-control"
                          v-if="areaData.area.editable"
                          v-model="form.remarks"
                        ></textarea>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="areaData.area.is_complied"
                          v-show="!areaData.area.editable"
                          class="font-weight-bold"
                          >&#10003;</span
                        >
                        <input
                          type="checkbox"
                          v-if="areaData.area.editable"
                          v-model="form.is_complied"
                          style="width: 16px; height: 16px;"
                        />
                      </td>
                      <td>
                        <div class="px-0">
                          <a
                            href="#"
                            class="mr-2"
                            @click.prevent="
                              updateReq(areaData.area.origIndex, areaData.area)
                            "
                            v-if="!areaData.area.editable"
                          >
                            <i class="bi bi-pencil-fill"></i>
                          </a>
                          <a
                            href="#"
                            class="mr-2 text-danger"
                            @click.prevent="areaData.area.editable = false"
                            v-if="areaData.area.editable"
                          >
                            X
                          </a>
                          <a
                            href="#"
                            @click.prevent="save"
                            v-if="areaData.area.editable"
                            class="mr-2"
                          >
                            <i class="bi bi-save-fill text-success"></i>
                          </a>

                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                            v-if="areaData.area.loader"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PrintReport
      v-show="false"
      :report="report"
      :reqs="reqs"
      ref="PrintReport"
    ></PrintReport>
  </div>
</template>

<script>
import PrintReport from './PrintReport.vue';

export default {
  name: 'ViewMonitoringReports',
  components: {
    PrintReport,
  },
  data() {
    return {
      reports: [],
      saveLoader: false,
      tableLoader: false,
      report: new Form({
        id: '',
        hei_name: '',
        area: '',
        mode: '',
        street: '',
        city: '',
        province: '',
        date_of_monitoring: '',
        monitored_by: '',
        president: '',
      }),
      form: new Form({
        id: '',
        is_available: '',
        remarks: '',
        is_complied: '',
        index: '',
      }),
      reqs: [],
    };
  },
  methods: {
    print() {
      this.$refs.PrintReport.print();
    },
    updateReq(index, area) {
      console.log(index);
      this.reqs[index].editable = true;
      this.form.fill(area);
      this.form.index = index;
    },

    save() {
      this.reqs[this.form.index].loader = true;

      axios
        .put(
          'api/hei/institutional-monitoring/req-evaluations/' + this.form.id,
          this.form
        )
        .then((res) => {
          this.reqs[this.form.index].editable = false;
          this.reqs[this.form.index].loader = false;

          //this.getAreasToMonitor(this.report.id, { loader: false });
          this.getInstReqEvaluations();
        })
        .catch((err) => {
          toastr.error('Oops! Unable to save changes.', 'Server Error');
          this.reqs[this.form.index].editable = false;
          this.reqs[this.form.index].loader = false;
        });
    },

    openReportModal(report) {
      $('#view-report-modal').modal('show');
      this.report.fill(report);
      this.getInstReqEvaluations();
    },
    getInstReqEvaluations() {
      axios
        .get(
          'api/hei/institutional-monitoring/req-evaluations/' + this.report.id
        )
        .then((res) => {
          this.reqs = res.data.map((obj) => ({
            ...obj,
            editable: false,
            loader: false,
          }));
        })
        .catch((err) => {});
    },
    getMonitoringReports(option) {
      this.tableLoader = true;

      axios
        .get('api/hei/institutional-monitoring/' + this.$route.params.id)
        .then((res) => {
          this.reports = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
  },
  computed: {
    processedReqs() {
      let groupedAreas = {};

      this.reqs.forEach((area, index) => {
        const areaName = area.general_requirement;

        if (!(areaName in groupedAreas)) {
          groupedAreas[areaName] = [];
        }

        area.origIndex = index;
        groupedAreas[areaName].push(area);
        // console.log('This is the current area: ' + area);
      });

      // console.log(groupedAreas);

      let result = [];
      let headerIndex = 0;
      let rowIndex = 0;

      // Iterate through the grouped areas and create the result array
      for (const areaName in groupedAreas) {
        const areas = groupedAreas[areaName];

        result.push({
          area: { name: areaName }, // Group header
          isGroupHeader: true,
          headerIndex: headerIndex,
        });

        headerIndex++;

        areas.forEach((area, indexInGroup) => {
          result.push({
            area,
            indexInGroup,
            isGroupHeader: false,
            rowIndex: rowIndex,
          });

          rowIndex++;
        });
      }

      return result;
    },
  },
  mounted() {
    this.getMonitoringReports({ loader: true });
  },
};
</script>

<style scoped>
#monitoring-reports-table tr {
  cursor: pointer;
}
</style>
