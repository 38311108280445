<template>
  <div>
    <div class="modal fade" id="add-finding-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addFinding">
            <div class="modal-body">
              <div class="alert alert-warning">
                <strong
                  ><i class="bi bi-exclamation-circle-fill mr-2"></i>Make it
                  short and concise.</strong
                >
              </div>
              <div class="form-row">
                <div class="col-4">
                  <div class="form-group">
                    <label for="">Code</label>
                    <input
                      type="text"
                      class="form-control text-uppercase"
                      maxlength="5"
                      required
                      v-model="newFinding.code"
                    />
                    <small class="form-text text-muted"
                      >Limited to 5 letters</small
                    >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Area</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="newFinding.area_id"
                      required
                    >
                      <option value="" selected hidden disabled>Choose</option>
                      <option
                        v-for="area in areas"
                        :key="area.id"
                        :value="area.id"
                      >
                        {{ area.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="">Description</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Ex. Dean is not qualified"
                  v-model="newFinding.description"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                type="submit"
                id="add-finding-btn"
                class="btn btn-primary"
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddFindingModal',
  props: {
    areas: {
      required: true,
    },
  },
  data() {
    return {
      newFinding: {
        code: '',
        area_id: '',
        description: '',
      },
    };
  },
  methods: {
    open() {
      $('#add-finding-modal').modal('show');
      this.newFinding.code = '';
      this.newFinding.area_id = '';
      this.newFinding.description = '';
    },

    addFinding() {
      let b = document.getElementById('add-finding-btn');
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Adding...`;
      b.disabled = true;
      axios
        .post('api/program-monitoring-reports/findings', this.newFinding)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          $('#add-finding-modal').modal('hide');
          this.$emit('addFinding', this.newFinding);
          b.innerHTML = 'Add';
          b.disabled = false;
        })
        .catch((err) => {
          b.innerHTML = 'Add';
          b.disabled = false;
          toastr.error(err.response?.data.message, 'Error');
        });
    },
  },
};
</script>
