<template>
  <div>
    <ul class="nav nav-tabs" id="program-monitoring-tabs">
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Compliance History</a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link  active"
          id="ViewMonitoringReports"
          href="#"
          @click.prevent="tabActive('ViewMonitoringReports')"
          >View Institutional Monitoring Reports</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="AddMonitoringReport"
          href="#"
          @click.prevent="tabActive('AddMonitoringReport')"
          >Add Institutional Monitoring Report</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="InstitutionalRequirements"
          href="#"
          @click.prevent="tabActive('InstitutionalRequirements')"
          >List of Institutional Requirements</a
        >
      </li>
    </ul>
    <br />
    <component v-bind:is="currentTab"> </component>
  </div>
</template>

<script>
import AddMonitoringReport from './components/AddMonitoringReport.vue';
import ViewMonitoringReports from './components/ViewMonitoringReports.vue';
import InstitutionalRequirements from './components/InstitutionalRequirements.vue';

export default {
  name: 'InstitutionalMonitoring',
  components: {
    AddMonitoringReport,
    ViewMonitoringReports,
    InstitutionalRequirements,
  },
  data() {
    return {
      currentTab: 'ViewMonitoringReports',
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $('#program-monitoring-tabs .nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },
  },
};
</script>
