<template>
  <div>
    <form @submit.prevent="submitMonitoringReport">
      <div class="form-row mb-1">
        <div class="col-auto">
          <div class="form-group">
            <label>Area</label>
            <select
              name="mode-of-eval"
              v-model="form.area"
              class="form-control"
              required
            >
              <option value="" disabled hidden selected>Choose</option>
              <option
                v-for="(area, index) in areas"
                :key="index"
                :value="area.name"
              >
                {{ area.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <label>Date of Monitoring</label>
            <input
              v-model="form.date_of_monitoring"
              name="date-of-monitoring"
              type="date"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <label>Mode</label>
            <select
              name="mode-of-eval"
              v-model="form.mode"
              class="form-control"
              required
            >
              <option value="" disabled hidden selected>Choose</option>
              <option v-for="mode in modes" :key="mode" :value="mode">
                {{ mode }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <label for="" class="invisible">Button</label>
            <button
              id="submit-monitoring-report-btn"
              class="btn btn-primary form-control"
              style="background-color:#321fdb; color: #fff"
              type="submit"
            >
              Create
            </button>
          </div>
        </div>
      </div>

      <div v-if="reqs.length" class="table-responsive">
        <hr />
        <div class="mb-3">
          <h5>
            Report ID: <strong>CHEDR13-IM-{{ report.id }}</strong>
          </h5>
        </div>
        <table class="table table-sm table-bordered mb-3">
          <thead>
            <tr>
              <th>REQUIREMENTS</th>
              <th>ACTUAL SITUATION</th>
              <th>COMPLIANCE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="areaData in processedReqs" :key="areaData.area.id">
              <template v-if="areaData.isGroupHeader">
                <td colspan="6">
                  <strong class="text-uppercase"
                    >{{ String.fromCharCode(65 + areaData.headerIndex) }}.
                    {{ areaData.area.name }}</strong
                  >
                </td>
              </template>

              <template v-else>
                <td class="" width="50%">
                  {{ areaData.indexInGroup + 1 }}.
                  {{ areaData.area.specific_requirement }}
                </td>

                <td>
                  <p
                    style="white-space: pre-wrap"
                    v-if="!areaData.area.editable"
                  >
                    {{ areaData.area.remarks }}
                  </p>
                  <textarea
                    v-else
                    name=""
                    class="form-control"
                    id=""
                    rows="5"
                    v-model="reqEval.remarks"
                  ></textarea>
                </td>
                <td class="text-center">
                  <span
                    v-if="areaData.area.is_complied"
                    v-show="!areaData.area.editable"
                    class="font-weight-bold"
                    >&#10003;</span
                  >
                  <input
                    v-model="reqEval.is_complied"
                    v-if="areaData.area.editable"
                    type="checkbox"
                    class="form-check-input"
                    style="width: 16px; height: 16px;"
                  />
                </td>
                <td>
                  <div class="px-0">
                    <button
                      class="mr-2 btn btn-link btn-sm"
                      @click.prevent="
                        editArea(areaData.area.origIndex, areaData.area)
                      "
                      v-if="!areaData.area.editable"
                      :disabled="!areaData.area.canEdit"
                    >
                      <i class="bi bi-pencil-fill text-primary"></i>
                    </button>
                    <button
                      class="mr-2 btn btn-link btn-sm "
                      @click.prevent="cancelEdit(areaData.area.origIndex)"
                      v-if="areaData.area.editable"
                    >
                      <i class="bi bi-x-circle-fill text-danger"></i>
                    </button>
                    <button
                      @click.prevent="save"
                      v-if="areaData.area.editable"
                      class="mr-3 btn btn-link btn-sm"
                    >
                      <i class="bi bi-save-fill text-success"></i>
                    </button>
                    <div
                      v-if="areaData.area.loader"
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>

        <div class="mt-4">
          <button class="btn btn-success mr-3" @click.prevent="print">
            <i class="bi bi-printer-fill mr-2"></i>Print/Save as PDF
          </button>
          <button
            class="btn btn-outline-secondary text-dark"
            @click.prevent="exportToCSV"
          >
            <i class="bi bi-exclamation-circle-fill mr-2"></i>Save Entries to
            Local File
          </button>
        </div>
      </div>
    </form>
    <PrintReport
      v-show="false"
      :report="report"
      :reqs="reqs"
      ref="PrintReport"
    ></PrintReport>
  </div>
</template>

<script>
import Papa from 'papaparse';
import PrintReport from './PrintReport.vue';

export default {
  name: 'AddMonitoringReport',
  components: {
    PrintReport,
  },
  data() {
    return {
      modes: ['Onsite', 'Virtual', 'Desktop'],
      areas: [
        { name: 'Administration and Non-Teaching Personnel' },
        { name: 'CHED Philippine Anti-Illegal Drugs Strategy (PADS)' },
        { name: 'Curriculum and SIPP' },
        { name: 'Extension and Linkages' },
        { name: 'Gender and Development' },
        { name: 'Governance and Management' },
        { name: 'Integration of Indigenous Peoples Studies/Education' },
        { name: 'Integration of Peace Studies/Education' },
        { name: 'Laboratories and Support Facilities' },
        { name: 'Library' },
        { name: 'National Service Training Program' },
        { name: 'Research and Linkages' },
        { name: 'Research, Extension, and Linkages' },
        { name: 'Student Affairs Services' },
        { name: 'Student Assistance and Services' },
        { name: 'Teaching and Learning Modality' },
      ],
      form: new Form({
        hei_code: this.$route.params.id,
        date_of_monitoring: '',
        mode: '',
        remarks: '',
        area: '',
      }),
      reqEval: new Form({
        id: '',
        remarks: '',
        is_complied: 0,
        index: '',
      }),
      report: {
        president: '',
      },
      loader: false,
      reqs: [],
      checkbox: false,
      showMsg: false,
    };
  },
  methods: {
    print() {
      this.$refs.PrintReport.print();
    },
    exportToCSV() {
      const csv = Papa.unparse(this.reqs);
      this.downloadCSV(csv, 'institutional_monitoring_backup.csv');
    },
    downloadCSV(csv, filename) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        // Other browsers
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    submitMonitoringReport() {
      let btn = document.getElementById('submit-monitoring-report-btn');
      btn.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Creating...`;
      btn.disabled = true;
      const encodeArea = this.form.area;
      const encodedArea = encodeURIComponent(encodeArea);

      axios
        .post('api/hei/institutional-monitoring', {
          form: this.form,
          encodedArea: encodedArea,
        })
        .then((res) => {
          this.report = res.data.report;
          this.getInstReqEvaluations();
        })
        .catch((err) => {
          toastr.error('Oops! Something went wrong.', 'Server Error');
          btn.disabled = false;
          btn.innerHTML = 'Create';
        });
    },
    getInstReqEvaluations() {
      let btn = document.getElementById('submit-monitoring-report-btn');
      axios
        .get(
          'api/hei/institutional-monitoring/req-evaluations/' + this.report.id
        )
        .then((res) => {
          this.reqs = res.data.map((obj) => ({
            ...obj,
            editable: false,
            loader: false,
            canEdit: true,
          }));

          btn.disabled = false;
          btn.innerHTML = 'Create';
        })
        .catch((err) => {});
    },
    editArea(index, req) {
      this.reqs[index].editable = true;
      this.disableEditActionExcept(index);
      this.reqEval.fill(req);
      this.reqEval.index = index;
    },
    cancelEdit(index) {
      this.reqs[index].editable = false;
      this.enableEditActionToAll();
    },
    enableEditActionToAll() {
      this.reqs.forEach((req, index) => {
        req.canEdit = true;
      });
    },
    disableEditActionExcept(indexToExclude) {
      this.reqs.forEach((req, index) => {
        req.canEdit = index === indexToExclude;
      });
    },
    save() {
      this.reqs[this.reqEval.index].loader = true;

      axios
        .put(
          'api/hei/institutional-monitoring/req-evaluations/' + this.reqEval.id,
          this.reqEval
        )
        .then((res) => {
          this.reqs[this.reqEval.index].editable = false;
          this.reqs[this.reqEval.index].loader = false;

          //this.getAreasToMonitor(this.report.id, { loader: false });
          this.getInstReqEvaluations();
        })
        .catch((err) => {
          toastr.error('Oops! Unable to save changes.', 'Server Error');
          this.reqs[this.reqEval.index].editable = false;
          this.reqs[this.reqEval.index].loader = false;
        });
    },
  },
  computed: {
    processedReqs() {
      let groupedAreas = {};

      this.reqs.forEach((area, index) => {
        const areaName = area.general_requirement;

        if (!(areaName in groupedAreas)) {
          groupedAreas[areaName] = [];
        }
        area.origIndex = index;
        groupedAreas[areaName].push(area);
      });

      let result = [];
      let headerIndex = 0;

      // Iterate through the grouped areas and create the result array
      for (const areaName in groupedAreas) {
        const areas = groupedAreas[areaName];

        result.push({
          area: { name: areaName }, // Group header
          isGroupHeader: true,
          headerIndex: headerIndex,
        });

        headerIndex++;

        areas.forEach((area, indexInGroup) => {
          result.push({
            area,
            indexInGroup,
            isGroupHeader: false,
          });
        });
      }

      return result;
    },
  },
  mounted() {},
};
</script>
