<template>
  <div>
    <div class="modal fade" id="view-report-modal">
      <div class="modal-dialog " style="max-width: 90%;">
        <div class="modal-content">
          <div class="modal-body">
            <div class="">
              <div class="btn btn-group px-0 py-0 mb-3">
                <button class="btn btn-primary" @click.prevent="print">
                  <i class="bi bi-printer-fill mr-2"></i>Print / Save as PDF
                </button>
              </div>
              <div class="table-responsive">
                <table class="table table-sm table-bordered">
                  <tbody>
                    <tr class="">
                      <th colspan="4">
                        <h5 class="font-weight-bold mb-0">
                          PROGRAM MONITORING REPORT
                        </h5>
                      </th>
                    </tr>
                    <tr>
                      <td width="20%">ID</td>
                      <th>CHEDR13-PM-{{ report.id }}</th>
                    </tr>
                    <tr>
                      <td>Mode of Inspection</td>
                      <th class="text-uppercase">
                        {{ report.mode_of_inspection }}
                      </th>
                    </tr>
                    <tr>
                      <td>Academic Year</td>
                      <th>{{ report.academic_year }}</th>
                    </tr>
                    <tr>
                      <td>Program</td>
                      <th class="text-uppercase">{{ report.program_name }}</th>
                    </tr>

                    <tr>
                      <td>Name of HEI</td>
                      <th class="text-uppercase">{{ report.hei_name }}</th>
                    </tr>

                    <tr>
                      <td>Address</td>
                      <th class="text-uppercase">
                        {{
                          `${report.street}, ${report.city}, ${report.province}`
                        }}
                      </th>
                    </tr>

                    <tr>
                      <td>Date and Time</td>
                      <th class="text-uppercase">
                        {{ report.date_of_inspection | standardDate }}
                      </th>
                    </tr>
                    <tr>
                      <td>Reference(s)</td>
                      <th class="text-uppercase">
                        <ol class="mb-1 pl-4">
                          <li v-for="(ref, index) in report.refs" :key="index">
                            <a href="#" @click.prevent="openUrl(ref.file)">{{
                              `${ref.code}: ${ref.title}`
                            }}</a>
                          </li>
                        </ol>
                      </th>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-bordered table-sm mt-3 ">
                  <thead>
                    <th width="35%">AREAS/REQUIREMENTS</th>

                    <th width="30%">FINDINGS/OBSERVATIONS</th>
                    <th width="5%">COMPLIANCE</th>
                    <th>REMARKS</th>
                    <th width="10%">ACTIONS</th>
                  </thead>
                  <tbody>
                    <tr class="text-center" v-if="tableLoader">
                      <td colspan="5">
                        <div
                          class="spinner-border spinner-border-sm mr-1"
                        ></div>
                        Loading
                      </td>
                    </tr>

                    <tr
                      v-for="areaData in processedProgramAreas"
                      :key="areaData.area.id"
                    >
                      <template v-if="areaData.isGroupHeader">
                        <td colspan="6">
                          <strong class="text-uppercase"
                            >{{
                              getAlphabeticalCharacter(areaData.headerIndex)
                            }}. {{ areaData.area.name }}</strong
                          >
                        </td>
                      </template>

                      <template v-else>
                        <td class="text-justify">
                          {{ areaData.indexInGroup + 1 }}.
                          {{ areaData.area.cmo_requirements }}
                        </td>

                        <td>
                          <ul class="pl-4 pr-0" v-if="!areaData.area.editable">
                            <li
                              v-for="(finding, index) in areaData.area.findings"
                              :key="index"
                            >
                              {{ `${finding.code} - ${finding.description}` }}
                            </li>
                          </ul>
                          <Multiselect
                            :options="areaData.area.options"
                            :custom-label="codeWithDesc"
                            track-by="description"
                            placeholder="Choose"
                            v-model="form.findings"
                            :searchable="true"
                            :multiple="true"
                            v-show="areaData.area.editable"
                          ></Multiselect>
                        </td>

                        <td class="text-center">
                          <span
                            v-if="areaData.area.is_complied"
                            v-show="!areaData.area.editable"
                            class="font-weight-bold"
                            >&#10003;</span
                          >
                          <input
                            type="checkbox"
                            v-model="form.is_complied"
                            v-if="areaData.area.editable"
                            style="width: 16px; height: 16px;"
                          />
                        </td>
                        <td>
                          <p
                            style="white-space: pre-wrap"
                            v-if="!areaData.area.editable"
                          >
                            {{ areaData.area.remarks }}
                          </p>
                          <textarea
                            v-if="areaData.area.editable"
                            v-model="form.remarks"
                            class="form-control"
                            cols="30"
                          ></textarea>
                        </td>
                        <td class="text-center">
                          <div class="px-0">
                            <a
                              href="#"
                              class="mr-2"
                              @click.prevent="
                                editArea(areaData.area.origIndex, areaData.area)
                              "
                              v-if="!areaData.area.editable"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              class="mr-2 text-danger"
                              @click.prevent="areaData.area.editable = false"
                              v-if="areaData.area.editable"
                            >
                              X
                            </a>
                            <a
                              href="#"
                              @click.prevent="save"
                              v-if="areaData.area.editable"
                              class="mr-2"
                            >
                              <i class="bi bi-save-fill text-success"></i>
                            </a>
                            <div
                              v-if="areaData.area.loader"
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>General remarks/recommendations</td>
                    </tr>
                    <tr>
                      <td>
                        <p style="white-space: pre-wrap;">
                          {{ report.general_remarks }}
                        </p>
                        <div
                          v-if="!genRemarksEditable"
                          @click.prevent="editGenRemarks"
                        >
                          <button class="btn btn-link">
                            <i class="bi bi-pencil-fill mr-2"></i> Add/Edit
                            General Remarks
                          </button>
                        </div>

                        <div class="form-group mt-3" v-else>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            class="form-control"
                            v-model="generalRemarks"
                          ></textarea>
                        </div>

                        <div>
                          <button
                            class="btn btn-link"
                            v-if="genRemarksEditable"
                            @click.prevent="saveGenRemarks"
                          >
                            <i class="bi bi-save-fill text-success mr-2"></i>
                            Save remarks
                          </button>

                          <div
                            v-if="saveGenRemarksLoader"
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <PrintReport
      ref="PrintReport"
      :report="report"
      :areas="areas"
      v-show="false"
    ></PrintReport>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PrintReport from './PrintReport.vue';

export default {
  name: 'ViewReportModal',
  components: {
    Multiselect,
    PrintReport,
  },
  data() {
    return {
      report: new Form({
        id: '',
        mode_of_inspection: '',
        date_of_inspection: '',
        program_name: '',
        hei_name: '',
        street: '',
        city: '',
        general_remarks: '',
        president: '',
        province: '',
        updated_by: '',
        academic_year: '',
        is_complied: '',
        created_at: '',
        refs: [],
      }),
      areas: [],
      findings: [],
      form: new Form({
        id: '',
        findings: [],
        remarks: '',
        is_complied: '',
        index: '',
      }),
      tableLoader: false,
      saveGenRemarksLoader: false,
      genRemarksEditable: false,
      generalRemarks: '',
    };
  },
  methods: {
    saveGenRemarks() {
      this.saveGenRemarksLoader = true;
      axios
        .put(
          'api/program-monitoring-reports/general-remarks/' + this.report.id,
          { generalRemarks: this.generalRemarks }
        )
        .then((res) => {
          this.genRemarksEditable = false;
          this.report.fill(res.data);
          this.report.refs = JSON.parse(res.data.refs);
          this.saveGenRemarksLoader = false;
        })
        .catch((err) => {
          this.saveGenRemarksLoader = false;
        });
    },

    editGenRemarks() {
      this.genRemarksEditable = true;
      this.generalRemarks = this.report.general_remarks;
    },
    openUrl(path) {
      const url = `https://drive.google.com/file/d/${path}/preview`;
      window.open(url, '_blank');
    },
    open(report) {
      this.report.fill(report);
      this.report.refs = JSON.parse(report.refs);
      $('#view-report-modal').modal('show');
      this.getAreasToMonitor(report.id, { loader: true });
    },

    getAreasToMonitor(report_id, config) {
      if (config.loader) {
        this.areas = [];
      }

      this.tableLoader = config.loader;

      axios
        .get('api/program-monitoring-reports/areas-to-monitor/' + report_id)
        .then((res) => {
          this.areas = res.data.map((obj) => ({
            ...obj,
            editable: false,
            loader: false,
            options: this.findings.filter((opt) => {
              return opt.area_id == obj.area_id;
            }),
          }));
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
    editArea(index, area) {
      this.areas[index].editable = true;
      this.form.fill(area);
      this.form.index = index;
    },
    save() {
      this.areas[this.form.index].loader = true;

      axios
        .put('api/program-monitoring-reports/' + this.form.id, this.form)
        .then((res) => {
          this.areas[this.form.index].editable = false;
          this.areas[this.form.index].loader = false;
          this.getAreasToMonitor(this.report.id, { loader: false });
        })
        .catch((err) => {
          toastr.error('Oops! Unable to fetch areas.', 'Server Error');
          this.areas[this.form.index].editable = false;
          this.areas[this.form.index].loader = false;
        });
    },
    getFindings() {
      axios.get('api/findings').then((res) => {
        this.findings = res.data;
        if (this.areas.length) {
          this.areas.forEach((area) => {
            area.options = this.findings.filter((opt) => {
              return opt.area_id == area.area_id;
            });
          });
        }
      });
    },
    codeWithDesc({ code, description }) {
      return `${code} - ${description}`;
    },
    print() {
      this.$refs.PrintReport.print();
    },
    getAlphabeticalCharacter(index) {
      return String.fromCharCode(65 + index); // 65 is the ASCII code for 'A'
    },
  },
  computed: {
    processedProgramAreas() {
      let groupedAreas = {};

      this.areas.forEach((area, index) => {
        const areaName = area.name;

        if (!(areaName in groupedAreas)) {
          groupedAreas[areaName] = [];
        }

        area.origIndex = index;
        groupedAreas[areaName].push(area);
      });

      let result = [];
      let headerIndex = 0;
      let rowIndex = 0;

      // Iterate through the grouped areas and create the result array
      for (const areaName in groupedAreas) {
        const areas = groupedAreas[areaName];

        result.push({
          area: { name: areaName }, // Group header
          isGroupHeader: true,
          headerIndex: headerIndex,
        });

        headerIndex++;

        areas.forEach((area, indexInGroup) => {
          result.push({
            area,
            indexInGroup,
            isGroupHeader: false,
            rowIndex: rowIndex,
          });

          rowIndex++;
        });
      }

      return result;
    },
  },
  mounted() {
    this.getFindings();
  },
};
</script>
